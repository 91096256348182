<template>
  <v-container>
    <v-row class="mb-2">
      <v-col>
        <v-data-table
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="draftEngagementItems"
          item-key="draftEngagementId"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Resultados Estimados Totales'"
          :buttonTag="changeUnitsButtonT1Tag"
          :hideFooter="true"
          :items="t1Metrics"
          :headers="tHeaders.t1Headers"
          @buttonClick="setMetricUnits('t1')"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Breakdown de Costos'"
          :hideFooter="true"
          :items="t4Metrics"
          :headers="tHeaders.t4Headers"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="
            'Rentabilidad Por Cultivo ' + ` Negocio Id:${draftEngagementId1}`
          "
          :buttonTag="changeUnitsButton1Tag"
          :hideFooter="true"
          :items="draftEngagement1T3Metrics"
          :headers="tHeaders.t3Headers1"
          :headersWidth="124"
          @buttonClick="setMetricUnits(draftEngagementId1)"
        />
      </v-col>
      <v-col>
        <AbstractDraftMetricDataTable
          :title="
            'Rentabilidad Por Cultivo ' +
            ` Negocio Id:${this.draftEngagementId2}`
          "
          :buttonTag="changeUnitsButton2Tag"
          :hideFooter="true"
          :items="draftEngagement2T3Metrics"
          :headers="tHeaders.t3Headers2"
          :headersWidth="124"
          @buttonClick="setMetricUnits(draftEngagementId2)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "DraftEngagementCompare",
  beforeMount() {
    let idArray = [this.draftEngagementId1, this.draftEngagementId2];
    this.$store.dispatch("getMetricsForEngagementComparison", idArray);
    this.$store.dispatch("getDraftEngagementsByIdArray", idArray);
  },
  data() {
    return {
      changeUnitsButtonT1Tag: "USD/ha",
      isUsdTotalMetricT1: true,
      metricUnitsT1: "USD",
      changeUnitsButton1Tag: "USD",
      changeUnitsButton2Tag: "USD",
      isUsdTotalMetric1: false,
      isUsdTotalMetric2: false,
      metricUnits1: "USD/ha",
      metricUnits2: "USD/ha",
      draftEngagementId1: this.$route.params.id1,
      draftEngagementId2: this.$route.params.id2,
      vDataTableHeaders: [
        { text: "Id", value: "draftEngagementId" },
        { text: "Nombre del Borrador", value: "draftName" },
        {
          text: "Region",
          value: "region",
        },
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Has. Físicas",
          value: "totalHectares",
        },
        {
          text: "Creado",
          value: "createdAt",
        },
        {
          text: "Arrendamientos qq/ha",
          value: "rentQqHa",
        },
        {
          text: "Precio en adelanto Arrendamientos",
          value: "rentQqActivityPrice",
        },
      ],
    };
  },
  methods: {
    setMetricUnits(id) {
      if (id == this.draftEngagementId1) {
        if (this.isUsdTotalMetric1) {
          this.changeUnitsButton1Tag = "USD";
          this.isUsdTotalMetric1 = false;
          this.metricUnits1 = "USD/ha";
        } else {
          this.changeUnitsButton1Tag = "USD/ha";
          this.isUsdTotalMetric1 = true;
          this.metricUnits1 = "USD";
        }
      } else if (id == this.draftEngagementId2) {
        if (this.isUsdTotalMetric2) {
          this.changeUnitsButton2Tag = "USD";
          this.isUsdTotalMetric2 = false;
          this.metricUnits2 = "USD/ha";
        } else {
          this.changeUnitsButton2Tag = "USD/ha";
          this.isUsdTotalMetric2 = true;
          this.metricUnits2 = "USD";
        }
      } else if (id == "t1") {
        if (this.isUsdTotalMetricT1) {
          this.changeUnitsButtonT1Tag = "USD";
          this.isUsdTotalMetricT1 = false;
          this.metricUnitsT1 = "USD/ha";
        } else {
          this.changeUnitsButtonT1Tag = "USD/ha";
          this.isUsdTotalMetricT1 = true;
          this.metricUnitsT1 = "USD";
        }
      }
    },
  },

  components: { AbstractDraftMetricDataTable },
  computed: {
    ...mapGetters([
      "t1MetricsComparison",
      "t4MetricsComparison",
      "t3MetricsComparison",
      "draftEngagementsToCompare",
    ]),
    draftEngagementItems() {
      return this.draftEngagementsToCompare.map((d) => {
        return {
          ...d,
          createdAt: new Date(d.createdAt)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
        };
      });
    },
    t1MetricsToLocalString() {
      let t1Metrics = this.t1MetricsComparison;
      t1Metrics.forEach((element) => {
        element["engagement_a"] = element["engagement_a"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["engagement_b"] = element["engagement_b"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["delta"] = element["delta"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        if (element?.["delta_ha"]) {
          element["engagement_a_ha"] = element[
            "engagement_a_ha"
          ].toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          });
          element["engagement_b_ha"] = element[
            "engagement_b_ha"
          ].toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          });
          element["delta_ha"] = element["delta_ha"].toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          });
        }
      });

      return t1Metrics;
    },
    t1Metrics() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            engagement_a:
              element.unit == "%"
                ? element?.["engagement_a"]
                : element?.["engagement_a_ha"],
            engagement_b:
              element.unit == "%"
                ? element?.["engagement_b"]
                : element?.["engagement_b_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
            delta:
              element.unit == "%" ? element?.["delta"] : element?.["delta_ha"],
          };
        });
      }
      return t1Metrics;
    },
    t4Metrics() {
      let t4Metrics = this.t4MetricsComparison;
      t4Metrics.forEach((element) => {
        element["engagement_a"] = element["engagement_a"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["engagement_b"] = element["engagement_b"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["engagement_a_ha"] = element["engagement_a_ha"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["engagement_b_ha"] = element["engagement_b_ha"].toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element["delta"] = element["delta"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element["delta_ha"] = element["delta_ha"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });
      return t4Metrics;
    },
    tHeaders() {
      return {
        t1Headers: [
          { text: "Indicator", value: "key", class: "secondary white--text" },
          {
            text: `Negocio Id:${this.draftEngagementId1}`,
            value: "engagement_a",
            class: "secondary white--text",
          },
          {
            text: `Negocio Id:${this.draftEngagementId2}`,
            value: "engagement_b",
            class: "secondary white--text",
          },
          {
            text: "delta",
            value: "delta",
            class: "secondary white--text",
          },
          {
            text: "Unit",
            value: "unit",
            class: "secondary white--text",
          },
        ],
        t4Headers: [
          { text: "Metric", value: "key", class: "secondary white--text" },
          {
            text: `Negocio Id:${this.draftEngagementId1}`,
            value: "engagement_a",
            class: "secondary white--text",
          },
          {
            text: `Negocio Id:${this.draftEngagementId2}`,
            value: "engagement_b",
            class: "secondary white--text",
          },
          {
            text: `Negocio Id:${this.draftEngagementId1} ha`,
            value: "engagement_a_ha",
            class: "secondary white--text",
          },
          {
            text: `Negocio Id:${this.draftEngagementId2} ha`,
            value: "engagement_b_ha",
            class: "secondary white--text",
          },
          { text: "delta", value: "delta", class: "secondary white--text" },
          {
            text: "delta ha",
            value: "delta_ha",
            class: "secondary white--text",
          },
        ],
        t3Headers1: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnits1,
            value: !this.isUsdTotalMetric1
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnits1,
            value: !this.isUsdTotalMetric1
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnits1,
            value: !this.isUsdTotalMetric1
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnits1,
            value: !this.isUsdTotalMetric1
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnits1,
            value: !this.isUsdTotalMetric1
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnits1,
            value: !this.isUsdTotalMetric1 ? "ivaNetByHectares" : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
        t3Headers2: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnits2,
            value: !this.isUsdTotalMetric2
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnits2,
            value: !this.isUsdTotalMetric2
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnits2,
            value: !this.isUsdTotalMetric2
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnits2,
            value: !this.isUsdTotalMetric2
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnits2,
            value: !this.isUsdTotalMetric2
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnits2,
            value: !this.isUsdTotalMetric2 ? "ivaNetByHectares" : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
      };
    },
    draftEngagement1T3Metrics() {
      let t3Metrics = this.t3MetricsComparison[this.draftEngagementId1];

      t3Metrics.forEach((element) => {
        element.indifferenceQqha = (
          element.indifferenceQqha || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalProfits = (element.totalProfits || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.totalCosts = (element.totalCosts || 0).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalSalesExpenses = (
          element.totalSalesExpenses || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalDirectCosts = (
          element.totalDirectCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalAutofinanciableCosts = (
          element.totalAutofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalNonautofinanciableCosts = (
          element.totalNonautofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.profitsByHectare = (
          element.profitsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalCostsByHectare = (
          element.totalCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.salesExpensesByHectare = (
          element.salesExpensesByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.directCostsByHectare = (
          element.directCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.autofinanciableCostsByHectare = (
          element.autofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.nonautofinanciableCostsByHectare = (
          element.nonautofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalIvaNet = (element.totalIvaNet || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.ivaNetByHectare = (element.ivaNetByHectare || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );

        element.netIncomeOverExpensesPercent = (
          element.netIncomeOverExpensesPercent || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t3Metrics;
    },
    draftEngagement2T3Metrics() {
      let t3Metrics = this.t3MetricsComparison[this.draftEngagementId2];

      t3Metrics.forEach((element) => {
        element.indifferenceQqha = (
          element.indifferenceQqha || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalProfits = (element.totalProfits || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.totalCosts = (element.totalCosts || 0).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalSalesExpenses = (
          element.totalSalesExpenses || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalDirectCosts = (
          element.totalDirectCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalAutofinanciableCosts = (
          element.totalAutofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalNonautofinanciableCosts = (
          element.totalNonautofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.profitsByHectare = (
          element.profitsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalCostsByHectare = (
          element.totalCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.salesExpensesByHectare = (
          element.salesExpensesByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.directCostsByHectare = (
          element.directCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.autofinanciableCostsByHectare = (
          element.autofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.nonautofinanciableCostsByHectare = (
          element.nonautofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalIvaNet = (element.totalIvaNet || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.ivaNetByHectare = (element.ivaNetByHectare || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );

        element.netIncomeOverExpensesPercent = (
          element.netIncomeOverExpensesPercent || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t3Metrics;
    },
  },
};
</script>

<style></style>
